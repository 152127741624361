
h1 {
  font-size: 2em;
}

.product-title {
  font-weight: bold;
  font-size: 1.2em;
}

.product-id {
  font-size: .8em;
  margin: 1em 0;
}

.product-edit-icon {
  opacity: .7;
  position: absolute;
  right: 2.3rem;
  top: .4rem;
}

.product-edit-icon:hover {
  opacity: 1;
}

.navbar, .navbar-end, .navbar-menu, .navbar-start {
  align-items: stretch !important;
  display: flex !important;
}

.navbar-menu {
  flex-grow: 1 !important;
  flex-shrink: 0 !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.navbar-item {
  display: flex !important;
}

.navbar-end {
  justify-content: flex-end !important;
  margin-left: auto !important;
}

.features {
  padding-bottom: 3em;
}

body {
  visibility: visible !important;
}


.auth form {
  margin-top: 1em;
  max-width: 50%;
}

.buttons {
  margin-left: 1em;
}
/* Overlay Styles */
.ReactModal__Overlay {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Content Styles */
  .ReactModal__Content {
    position: relative;
    background-color: #fff;
    border-radius: 4px;
    padding: 20px;
    max-width: 400px;
    height: 30%;
    margin: 0 auto; /* Center horizontally */
    margin-top: 10%;
    text-align: center;
  }
  
  /* Title Styles */
  .ReactModal__Content h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  /* Button Styles */
  .ReactModal__Content button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .ReactModal__Content button:hover {
    background-color: #0056b3;
  }
  
  .ReactModal__Content p {
    margin-bottom: 20px;
  }
