/* Overlay Styles */
.ReactModal__Overlay {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Content Styles */
  .ReactModal__Content {
    position: relative;
    background-color: #fff;
    border-radius: 4px;
    padding: 20px;
    max-width: 400px;
    height: 30%;
    margin: 0 auto; /* Center horizontally */
    margin-top: 10%;
    text-align: center;
  }
  
  /* Title Styles */
  .ReactModal__Content h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  /* Button Styles */
  .ReactModal__Content button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .ReactModal__Content button:hover {
    background-color: #0056b3;
  }
  
  .ReactModal__Content p {
    margin-bottom: 20px;
  }